<template>
  <div class="tw-relative tw-h-1 loading-container tw-overflow-hidden tw-w-full tw-bg-gray-200 tw--mb-1">
  </div>
</template>

<script>
export default {

};
</script>

<style lang="stylus" scoped>
.loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(36, 99, 235, 0.8), transparent);
  animation: loadingBar 0.8s infinite;
}

@keyframes loadingBar {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>