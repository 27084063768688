import Vue from 'vue'
import store from 'src/store'

import { ApolloClient } from 'apollo-client'
import {ApolloLink, concat} from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'

const environment = require('src/environment.js')

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: environment('backendGraphQlUrl'),
})

const sessionMiddleware = new ApolloLink((operation, forward) => {
  if(store.state.sessionToken) {
    operation.setContext({
      headers: {
        'X-SESSION-TOKEN': store.state.sessionToken
      }
    });
  }
  return forward(operation);
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(sessionMiddleware, httpLink),
  cache,
})

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export default apolloProvider