<template>
  <div
    class="tw-grid tw-h-full tw-w-full tw-content-between tw-rounded-xl tw-bg-cover"
    :style="elementVisible ? 'background-image:' + titleImage.image + ';' + 'background-size: cover;' + 'background-position: ' + titleImage.position + ';' : ''"
  >
    <!-- Top -->
    <div class="tw-flex tw-flex-wrap tw-content-between tw-justify-between tw-gap-2 tw-rounded-xl tw-px-6 tw-pb-8 tw-pt-8" style="background: linear-gradient(180deg, #2f2f2f -60%, rgba(0, 0, 0, 0) 100%)">
      <helios-badge color="transparent">Blogartikel vom {{ mxn_formatDateTime(new Date(contentArticleBlog.publishedDate), 'dd.MM.yyyy', 1) }}</helios-badge>
    </div>

    <!-- Bottom -->
    <div class="tw-rounded-xl tw-px-6 tw-pb-6 tw-pt-12" style="background: linear-gradient(360deg, #1f1f1f 20%, rgba(0, 0, 0, 0) 100%)">
      <helios-badge color="transparent" class="tw-mb-2.5">{{ contentArticleBlog.page.title }}</helios-badge>

      <div class="tw-mb-2 tw-line-clamp-3 tw-text-lg tw-font-bold tw-leading-tight tw-text-white">{{ contentArticleBlog.title }}</div>
      <div class="tw-line-clamp-4 tw-text-base tw-leading-tight tw-text-white">{{ contentArticleBlog.short }}</div>

      <div class="tw-mt-4 tw-flex tw-justify-end">
        <router-link class="tw-text-left tw-text-white tw-no-underline" :to="{ name: searchOptions.blogArticleTargetPage, params: { blogArticleSlug: contentArticleBlog.slug } }">
          <div class="tw-flex tw-items-center">
            <div class="tw-mr-2">
              <span class="tw-line-clamp-1 tw-text-sm tw-font-semibold"> Blogartikel lesen </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-block tw-h-8 tw-w-8 tw-rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import { FormatDateTimeMixin } from 'helios/mixins/formatDateTime';

  export default {
    mixins: [FormatDateTimeMixin],
    props: {
      contentArticleBlog: {
        required: true,
        type: Object,
      },
      searchOptions: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        elementVisible: false,
      };
    },
    computed: {
      titleImage() {
        if (this.contentArticleBlog.titleImage.length > 0) {
          return {
            image: 'url("' + this.contentArticleBlog.titleImage.find((item) => item.variant === 'ContentArticle_TitleImage_800w').link + '")',
            position: 'center center',
          };
        } else {
          return {
            image: 'url("' + this.$store.state.site.website.stage.defaultBackgroundMid + '")',
            position: 'right bottom',
          };
        }
      },
    },
    mounted() {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.elementVisible = true;
          observer.disconnect();
        }
      });

      observer.observe(this.$el);
    },
  };
</script>
