import Vue from "vue";

const GetFromNestedObjectPlugin = {
    install(Vue) {
        Vue.prototype.getFromNestedObject = (fn, defaultVal) => {
            try {
                if(fn() === undefined) {
                    return defaultVal
                }
                else {
                    return fn()
                }
            } catch (e) {
                return defaultVal
            }
        }
    },
}

export default Vue.use(GetFromNestedObjectPlugin)