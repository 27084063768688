<!-- https://tailwindui.com/components/application-ui/feedback/alerts -->
<template>
  <div
    class="tw-rounded-md tw-border-0 tw-border-l-4 tw-border-solid tw-px-4 tw-py-3"
    v-bind:class="{
      'tw-bg-blue-50 tw-border-blue-400': type === 'primary',
      'tw-bg-yellow-50 tw-border-yellow-400': type === 'warning',
      'tw-bg-red-50 tw-border-red-400': type === 'error',
      'tw-bg-green-50 tw-border-green-400': type === 'success',
    }"
  >
    <div class="tw-flex">
      <div v-if="icon === true">
        <div class="tw-mr-3 tw-flex-shrink-0">
          <!-- Heroicon name: mini/exclamation-triangle -->
          <svg class="tw-h-5 tw-w-5 tw-text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="type === 'warning'">
            <path
              fill-rule="evenodd"
              d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>

          <!-- Heroicon name: mini/x-circle -->
          <svg class="tw-h-5 tw-w-5 tw-text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="type === 'error'">
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd"
            />
          </svg>

          <!-- Heroicon name: mini/check-circle -->
          <svg class="tw-h-5 tw-w-5 tw-text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="type === 'success'">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
          </svg>

          <!-- Heroicon name: mini/information-circle -->
          <svg class="tw-h-5 tw-w-5 tw-text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="type === 'primary'">
            <path
              fill-rule="evenodd"
              d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div>
        <div
          v-if="$slots.headline"
          class="tw-mb-1 tw-text-sm tw-font-semibold"
          v-bind:class="{
            'tw-text-blue-800': type === 'primary',
            'tw-text-yellow-800': type === 'warning',
            'tw-text-red-800': type === 'error',
            'tw-text-green-800': type === 'success',
          }"
        >
          <slot name="headline"></slot>
        </div>
        <div
          class="tw-text-sm"
          v-bind:class="{
            'tw-text-blue-700': type === 'primary',
            'tw-text-yellow-700': type === 'warning',
            'tw-text-red-700': type === 'error',
            'tw-text-green-700': type === 'success',
          }"
        >
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      type: {
        type: String,
        required: false,
        default: 'primary',
      },
      icon: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>
