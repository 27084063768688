let axios = require('axios');
const environment = require('src/environment.js');
import store from 'store';

export function result(args) {
  return new Promise((resolve) => {
    let request = {
      version: true,
      query: {
        bool: {
          filter: [],
        },
      },
      sort: [
        {
          _score: { order: 'desc' }, // Sort by relevance score first
        },
        {
          'page.courseDateSetList.totalCount': { order: 'desc' },
        },
        {
          'courseDateSet.nextAvailableCourseDateSetDateForSingleOrRowBuying_startDateTime': { order: 'asc' },
        },
        {
          'subscription.title.keyword': { order: 'asc' },
        },
        {
          'contentArticleBlog.publishedDate': { order: 'desc' },
        },
        {
          'contentArticleHelp.title.keyword': { order: 'asc' },
        },
      ],
      docvalue_fields: [],
      _source: {
        excludes: [],
      },
    };

    // Reduce to channel

    request.query.bool.filter.push({
      bool: {
        should: [{ match_phrase: { 'channels.keyword': store.state.site.backend.token } }],
      },
    });

    // searchPhrase

    if (args.searchPhrase !== '') {
      const searchTokens = args.searchPhrase.toLocaleLowerCase().split(' ');
      const shouldQueries = [];

      // Add exact match first with higher boost
      shouldQueries.push({
        match: {
          keywords: {
            query: args.searchPhrase.toLocaleLowerCase(),
            operator: 'and',
            boost: 3,
          },
        },
      });

      // Add individual tokens as wildcard matches
      searchTokens.forEach((token) => {
        shouldQueries.push({
          wildcard: {
            keywords: {
              value: '*' + token + '*',
              boost: 2,
            },
          },
        });
      });

      // Ensure that the `must` clause is initialized
      if (!request.query.bool.must) {
        request.query.bool.must = [];
      }

      request.query.bool.must.push({
        bool: {
          should: shouldQueries,
          minimum_should_match: 1,
        },
      });
    }

    // typeNames filter

    let typeNameFilters = [];

    args.criteria.typeNames.forEach((element) =>
      typeNameFilters.push({
        match_phrase: {
          'criteria.type': element,
        },
      })
    );

    if (typeNameFilters.length > 0) {
      request.query.bool.filter.push({
        bool: {
          should: typeNameFilters,
        },
      });
    }

    // pageSid filter

    let pageSidFilters = [];

    if (args.criteria && Array.isArray(args.criteria.pageSids)) {
      args.criteria.pageSids.forEach((element) =>
        pageSidFilters.push({
          match_phrase: {
            'criteria.pageSid': element,
          },
        })
      );
    }

    if (pageSidFilters.length > 0) {
      request.query.bool.filter.push({
        bool: {
          should: pageSidFilters,
        },
      });
    }

    // pageTitle filter

    let pageTitleFilters = [];

    if (args.criteria && Array.isArray(args.criteria.pageTitles)) {
      args.criteria.pageTitles.forEach((element) =>
        pageTitleFilters.push({
          match_phrase: {
            'criteria.pageTitle': element,
          },
        })
      );

      if (pageTitleFilters.length > 0) {
        request.query.bool.filter.push({
          bool: {
            should: pageTitleFilters,
          },
        });
      }
    }

    // categoryTitle filter

    let categoryTitleFilters = [];

    if (args.criteria && Array.isArray(args.criteria.categoryTitles)) {
      args.criteria.categoryTitles.forEach((element) =>
        categoryTitleFilters.push({
          match_phrase: {
            'criteria.categorys': element,
          },
        })
      );

      if (categoryTitleFilters.length > 0) {
        request.query.bool.filter.push({
          bool: {
            should: categoryTitleFilters,
          },
        });
      }
    }

    // StartDate filter

    if (args.courseDateSetStartDateFrom) {
      //Should have a startDate in the future or be a Page
      request.query.bool.filter.push({
        bool: {
          should: [
            {
              range: {
                'courseDateSet.courseDateSetDateList.edges.node.startDate': {
                  gte: args.courseDateSetStartDateFrom,
                },
              },
            },
            {
              match_phrase: {
                type: 'Page',
              },
            },
            {
              match_phrase: {
                type: 'Subscription',
              },
            },
            {
              match_phrase: {
                type: 'ContentArticleBlog',
              },
            },
            {
              match_phrase: {
                type: 'ContentArticleHelp',
              },
            },
          ],
        },
      });
    }

    request.from = args.from || 0;
    request.size = args.size || 10;

    request.aggs = {
      typeAggs: {
        terms: {
          field: 'criteria.type.keyword',
          size: 200,
        },
      },
      pageAggs: {
        terms: {
          field: 'criteria.pageTitle.keyword',
          size: 200,
        },
      },
      categoryAggs: {
        terms: {
          field: 'criteria.categorys.keyword',
          size: 200,
        },
      },
    };

    try {
      axios
        .post(environment('elastic').url + environment('elasticPrefix') + '-unified/_search', request, {
          auth: {
            username: environment('elastic').user,
            password: environment('elastic').password,
          },
        })
        .then((response) => {
          resolve(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  });
}
