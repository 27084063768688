<template>
  <div class="tw-flex">
    <div>
      <input
        v-model="model"
        :value="value"
        type="checkbox"
        :name="instanceUid"
        :id="instanceUid"
        class="tw-h-4 tw-w-4 tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-500"
        :placeholder="placeholder"
      />
    </div>
    <div class="tw-relative tw-ml-2 tw-mt-0.5">
      <label v-if="$slots.label" :for="instanceUid" class="tw-block tw-text-sm tw-font-medium"><slot name="label"></slot></label>
      <p class="tw-text-sm tw-text-red-600" id="field-error" v-if="errors.length > 0">{{ errors[0] }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      placeholder: {
        type: String,
        required: false,
      },
      value: {
        type: Boolean,
        required: false,
      },
      errors: {
        type: Array,
        required: false,
        default() {
          return [];
        },
      },
      responseBoolean: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        instanceUid: undefined,
        model: this.value,
      };
    },
    watch: {
      value: {
        deep: true,
        handler() {
          this.model = this.value;
        },
      },
      model: {
        deep: true,
        handler() {
          this.emit(this.model);
        },
      },
    },
    methods: {
      emit(val) {
        if (this.responseBoolean === true) {
          if (val === true) {
            this.$emit('input', true);
          } else {
            this.$emit('input', false);
          }
        } else {
          if (val === true) {
            this.$emit('input', '1');
          } else {
            this.$emit('input', '');
          }
        }
        this.$emit('change');
      },
    },
    mounted() {
      this.instanceUid = this._uid;
    },
  };
</script>
