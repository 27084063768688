const createEnvironment = (option) => {
  switch (option) {
    case 'backendUrl':
      switch (process.env.VUE_APP_ENV) {
        case 'dev':
          return 'http://localhost/public/';
        case 'dev-stage':
        case 'stage':
        case 'local-stage':
          return 'https://stage-joinsports.io/';
        case 'dev-prod':
        case 'prod':
          return 'https://joinsports.io/';
      }
      break;
    case 'backendGraphQlUrl':
      switch (process.env.VUE_APP_ENV) {
        case 'dev':
          return 'http://localhost/public/graphql/';
        case 'dev-stage':
        case 'stage':
        case 'local-stage':
          return 'https://stage-joinsports.io/graphql/';
        case 'dev-prod':
        case 'prod':
          return 'https://joinsports.io/graphql/';
      }
      break;
    case 'elastic':
      return {
        url: 'https://elastic.joinsports.io/',
        user: 'public',
        password: 'q8l2nuseaolemal2qagoser4f!!XX',
      };
    case 'elasticPrefix':
      switch (process.env.VUE_APP_ENV) {
        case 'dev':
        case 'dev-prod':
          return 'dev1';
        case 'dev-stage':
        case 'stage':
        case 'local-stage':
          return 'stage';
        case 'prod':
          return 'prod';
      }
      break;
    default:
      return 'UNKNOWN';
  }
};

module.exports = function (option) {
  return createEnvironment(option);
};
