<template>
  <div v-if="show" class="">
    <div class="tw-relative tw-z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"></div>
      <div
        class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto md:tw-mb-0"
        :style="{
          marginBottom: `${$store.state.nativeSafeAreaInsets.bottom > 0 ? $store.state.nativeSafeAreaInsets.bottom + 64 + 12 : $store.state.nativeSafeAreaInsets.bottom + 64}px`,
        }"
      >
        <div class="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-pt-16 sm:tw-items-center sm:tw-p-0">
          <div
            class="tw-relative tw-w-full tw-transform tw-overflow-hidden tw-rounded-t-2xl tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-max-w-2xl sm:tw-rounded-2xl sm:tw-p-6"
          >
            <div class="tw-absolute tw-right-0 tw-top-0 tw-pr-5 tw-pt-6 sm:tw-pt-8">
              <button type="button" class="tw-rounded-md tw-border-none tw-bg-white tw-text-gray-500 hover:tw-text-gray-700" @click="leave">
                <!-- Heroicon name: outline/x -->
                <svg class="tw-h-6 tw-w-6 sm:tw-h-8 sm:tw-w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div>
              <div>
                <div class="tw-text-lg tw-font-bold tw-text-gray-900 sm:tw-text-2xl">Filter</div>
                <div class="tw-mt-2"></div>
              </div>

              <!-- SearchPhrase Filter -->
              <div class="tw-my-6" v-if="searchOptionsProcessed.display.searchPhrase || 1 === 1">
                <div class="tw-text-base tw-font-semibold tw-text-gray-900">Suchbegriff</div>
                <div class="tw-mt-2 tw-flex">
                  <input
                    v-model="searchPhrase"
                    type="text"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-ring-1 tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-indigo-500 sm:tw-text-sm"
                    placeholder="Suchbegriff..."
                    @keyup="dispatchSearchPhrase()"
                  />
                </div>
              </div>

              <!-- Type filter -->
              <div class="tw-my-6" v-if="searchOptionsProcessed.display.typeNames">
                <div class="tw-text-base tw-font-semibold tw-text-gray-900">Ergebnisart</div>
                <div class="tw-mt-1 tw-grid tw-grid-cols-2">
                  <div v-for="item in innerFilterObject.typeNames" :key="item.value">
                    <helios-input-checkbox v-model="item.selected" :value="item.selected" @change="dispatchFilter()" response-boolean>
                      <template v-slot:label>
                        {{ item.value }}
                      </template>
                    </helios-input-checkbox>
                  </div>
                </div>
              </div>

              <!-- Page Filter -->
              <div class="tw-my-6" v-if="searchOptionsProcessed.display.pageTitles">
                <div class="tw-text-base tw-font-semibold tw-text-gray-900">Verein</div>
                <div class="tw-mt-2 tw-flex">
                  <input
                    v-model="pageTitleSearchPhrase"
                    type="text"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-ring-1 tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-indigo-500 sm:tw-text-sm"
                    :placeholder="'Aus ' + filterOptionsProcessed.pageTitles.length + ' Vereinen suchen...'"
                  />
                </div>
                <div class="tw-mt-2 tw-max-h-[132px] tw-overflow-y-scroll tw-overscroll-contain">
                  <div v-for="item in pageTitlesSearchedList" :key="item.value">
                    <helios-input-checkbox v-model="item.selected" :value="item.selected" @change="dispatchFilter()" response-boolean>
                      <template v-slot:label>
                        {{ item.value }}
                      </template>
                    </helios-input-checkbox>
                  </div>
                </div>
              </div>

              <!-- Category Filter -->
              <div class="tw-my-6" v-if="searchOptionsProcessed.display.categoryTitles">
                <div class="tw-text-base tw-font-semibold tw-text-gray-900">Kategorie</div>
                <div class="tw-mt-2 tw-flex">
                  <input
                    v-model="categoryTitleSearchPhrase"
                    type="text"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-ring-1 tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-indigo-500 sm:tw-text-sm"
                    :placeholder="'In ' + filterOptionsProcessed.categoryTitles.length + ' Kategorien suchen...'"
                  />
                </div>
                <div class="tw-mt-2 tw-max-h-[132px] tw-overflow-y-scroll tw-overscroll-contain">
                  <div v-for="item in categoryTitlesSearchedList" :key="item.value">
                    <helios-input-checkbox v-model="item.selected" :value="item.selected" @change="dispatchFilter()" response-boolean>
                      <template v-slot:label>
                        {{ item.value }}
                      </template>
                    </helios-input-checkbox>
                  </div>
                </div>
              </div>

              <!-- Actions -->
              <div class="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                <helios-button type="primary" class="tw-mb-1 sm:tw-mb-0 sm:tw-ml-3" @click="showResult">{{ hits }} Ergebnisse anzeigen</helios-button>
                <!--<tw-button type="default" @click="leave">Abbrechen</tw-button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { EscapeHandler } from 'helios/mixins/escapeHandler';

  export default {
    mixins: [EscapeHandler],
    props: {
      searchResult: {
        required: true,
        type: Object,
      },
      filterOptionsProcessed: {
        required: true,
        type: Object,
      },
      searchOptionsProcessed: {
        required: true,
        type: Object,
      },
    },
    components: {},
    data() {
      return {
        show: false,
        innerFilterObject: undefined,
        searchPhrase: '',
        pageTitleSearchPhrase: '',
        categoryTitleSearchPhrase: '',
      };
    },
    computed: {
      hits() {
        return this.searchResult.hits.total.value;
      },
      pageTitlesSearchedList() {
        if (this.pageTitleSearchPhrase !== '') {
          return this.innerFilterObject.pageTitles.filter((item) => item.value.toLowerCase().includes(this.pageTitleSearchPhrase.toLowerCase()));
        } else {
          return this.innerFilterObject.pageTitles;
        }
      },
      categoryTitlesSearchedList() {
        if (this.categoryTitleSearchPhrase !== '') {
          return this.innerFilterObject.categoryTitles.filter((item) => item.value.toLowerCase().includes(this.categoryTitleSearchPhrase.toLowerCase()));
        } else {
          return this.innerFilterObject.categoryTitles;
        }
      },
    },
    watch: {
      $route() {
        this.leave();
      },
      filterOptionsProcessed: {
        deep: true,
        handler() {
          this.updateInnerFilter();
        },
      },
    },
    methods: {
      enter() {
        // Reset data on every entry
        Object.assign(this.$data, this.$options.data());

        this.updateInnerFilter();
        this.searchPhrase = this.searchOptionsProcessed.searchPhrase;

        // Set start values
        this.show = true;
      },
      showResult() {
        this.show = false;
      },
      dispatchFilter() {
        this.categoryTitleSearchPhrase = '';
        this.pageTitleSearchPhrase = '';
        //console.log('---- inner filter dispatched', this.innerFilterObject)
        this.$emit('filter-changed', this.innerFilterObject);
      },
      dispatchSearchPhrase() {
        this.$emit('search-phrase-changed', this.searchPhrase);
      },
      updateInnerFilter() {
        //console.log('---- inner filter updated', this.innerFilterObject);
        this.innerFilterObject = JSON.parse(JSON.stringify(this.filterOptionsProcessed));
      },
      leave() {
        this.show = false;
      },
      escapeHandler() {
        this.leave();
      },
    },
    mounted() {},
  };
</script>
