<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
  import gql from 'graphql-tag';
  export default {
    props: {
      token: {
        type: String,
        required: true,
        default: undefined,
      },
    },
    components: {},
    data: () => ({}),
    methods: {
      init() {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($input: SessionCreateInput!) {
                sessionCreate(input: $input) {
                  token
                }
              }
            `,
            variables: {
              input: {
                channelToken: this.token,
              },
            },
          })
          .then((result) => {
            console.log('---- session created: ' + result.data.sessionCreate.token);
            this.$store.commit('setSessionToken', result.data.sessionCreate.token);
            this.$router.push({ name: 'App' });
          })
          .catch((error) => {
            console.log('---- error during session creation');
            console.log(error);
          });
      },
    },
    created() {
      this.init();
      this.$store.commit('setBackendToken', this.token);
    },
  };
</script>
<style lang="stylus">
  joinsports-widget-eris
    contain size layout style
</style>
<style>
  @import '../style/tailwind.css';
</style>
