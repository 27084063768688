<template>
  <div
    class="tw-grid tw-h-full tw-w-full tw-content-between tw-overflow-hidden tw-rounded-xl tw-bg-cover"
    :style="elementVisible ? 'background-image:' + titleImage.image + ';' + 'background-size: cover;' + 'background-position: ' + titleImage.position + ';' : ''"
  >
    <!-- Top -->
    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-rounded-xl tw-px-6 tw-pb-8 tw-pt-8" style="background: linear-gradient(180deg, #2f2f2f -60%, rgba(0, 0, 0, 0) 100%)">
      <!-- This is the badge for the availability of the next course date set date. Has been replaced -->
      <!--<helios-badge color="transparent">
        <div class="tw-mr-1.5 tw-h-2 tw-w-2 tw-rounded-full" v-bind:class="mxn_courseDateSet_getCourseDateSetDateAvailabilityObject(nextCourseDateSetDate).backgroundColorClass"></div>
        {{ mxn_courseDateSet_getCourseDateSetDateAvailabilityObject(nextCourseDateSetDate).text }}
      </helios-badge>-->

      <helios-badge color="transparent" v-if="courseDateSet.nextAvailableCourseDateSetDateForSingleOrRowBuying_startDateTime">
        <div class="tw-mr-1.5 tw-h-2 tw-w-2 tw-rounded-full tw-bg-green-600"></div>
        Freie Termine ab {{ this.formatDateTime(new Date(courseDateSet.nextAvailableCourseDateSetDateForSingleOrRowBuying_startDateTime), 'dd.MM.yyyy', 1) }}
      </helios-badge>
      <helios-badge color="transparent" v-if="!courseDateSet.nextAvailableCourseDateSetDateForSingleOrRowBuying_startDateTime">
        <div class="tw-mr-1.5 tw-h-2 tw-w-2 tw-rounded-full tw-bg-red-600"></div>
        Ausgebucht
      </helios-badge>

      <helios-badge color="transparent" v-if="buyableSingle === true">Einzeln buchbar</helios-badge>
      <helios-badge color="transparent" v-if="buyableSingle === false">Terminreihe</helios-badge>

      <helios-badge color="transparent" v-if="courseDateSet.trialParticipationAllowed === true">kostenfreies Probetraining buchbar</helios-badge>
    </div>

    <!-- Bottom -->
    <div class="tw-rounded-xl tw-px-6 tw-pb-6 tw-pt-12" style="background: linear-gradient(360deg, #1f1f1f 30%, rgba(0, 0, 0, 0) 100%)">
      <helios-badge color="transparent" class="tw-mb-2.5">{{ courseDateSet.page.title }}</helios-badge>

      <!-- Title -->
      <div class="tw-mb-2 tw-text-lg tw-font-bold tw-leading-tight tw-text-white">{{ courseDateSet.title }}</div>

      <!-- Price -->
      <div class="tw-mb-2 tw-text-white">
        <!-- Single price -->
        <div v-if="buyableSingle" class="tw-flex tw-place-items-end">
          <div class="tw-mr-2 tw-text-lg tw-font-semibold">ab {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(priceSingleCheepest / 100) }}</div>
          <div class="tw-pb-[2px] tw-text-sm">pro Termin</div>
        </div>

        <!-- Row price -->
        <div v-if="!buyableSingle && buyableRow" class="tw-flex tw-place-items-end">
          <div class="tw-mr-2 tw-text-lg tw-font-semibold">ab {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(priceRowCheepest / 100) }}</div>
          <div v-if="countDates === countFutureDates" class="tw-pb-[2px] tw-text-sm">für {{ countFutureDates }} Termine</div>
          <div v-if="countDates > countFutureDates" class="tw-pb-[2px] tw-text-sm">für übrige {{ countFutureDates }} Termine</div>
        </div>
      </div>

      <!-- Date time -->
      <div class="tw-mt-0.5 tw-text-sm tw-text-white">
        {{ new Date(nextDateDateTime).toLocaleDateString('de-DE', { weekday: 'long' }) }}s, <span class="tw-whitespace-nowrap">{{ nextDateFormattedTime }} Uhr</span>
      </div>
      <div class="tw-mt-0.5 tw-text-sm tw-text-white" v-if="buyableRow === true && countDates === countFutureDates">
        {{ countDates }} Termine <span class="tw-whitespace-nowrap tw-text-sm">ab {{ firstDateFormattedDate }}</span>
      </div>
      <div class="tw-mt-0.5 tw-text-sm tw-text-white" v-if="buyableRow === true && countDates > countFutureDates">
        {{ countDates }} Termine <span class="tw-whitespace-nowrap tw-text-sm">seit {{ firstDateFormattedDate }}</span>
      </div>

      <div class="tw-mt-4 tw-flex tw-justify-end">
        <router-link
          class="tw-text-left tw-text-white tw-no-underline"
          :to="{ name: 'AppPageCourseDateSetsCourseDateSet', params: { pageAlias: courseDateSet.page.alias, courseDateSetAlias: courseDateSet.alias + '-' + courseDateSet.sid } }"
          v-if="this.searchOptions.linkExternal === false"
        >
          <div class="tw-flex tw-items-center">
            <div class="tw-mr-2">
              <span class="tw-line-clamp-1 tw-text-sm tw-font-semibold"> Kursangebot öffnen </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-block tw-h-8 tw-w-8 tw-rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </router-link>
        <a
          :href="'https://joinsports.de/' + courseDateSet.page.alias + '/courses/' + courseDateSet.alias + '-' + courseDateSet.sid"
          :title="courseDateSet.title + ' bei joinsports.de'"
          v-if="this.searchOptions.linkExternal === true"
          class="tw-cursor-pointer tw-text-white tw-no-underline"
          target="_blank"
        >
          <div class="tw-flex tw-items-center">
            <div class="tw-mr-2">
              <span class="tw-line-clamp-1 tw-text-sm tw-font-semibold"> Kursangebot auf joinsports.de öffnen </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-block tw-h-8 tw-w-8 tw-rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  import { CourseDateSetMixin } from 'helios/mixins/courseDateSet';
  export default {
    mixins: [CourseDateSetMixin],
    props: {
      courseDateSet: {
        required: true,
        type: Object,
      },
      searchOptions: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        elementVisible: false,
      };
    },
    computed: {
      titleImage() {
        if (this.courseDateSet.mediaCollectionMain.mediaCollectionItems.length > 0) {
          return {
            image: 'url("' + this.courseDateSet.mediaCollectionMain.mediaCollectionItems[0].mediaDeliveryFiles.find((item) => item.variant === 'CourseDateSet_MediaCollection_Main_880w').link + '")',
            position: 'center center',
          };
        } else {
          return {
            image: 'url("' + this.$store.state.site.website.stage.defaultBackgroundMid + '")',
            position: 'right bottom',
          };
        }
      },
      startDateFrom() {
        let input = this.getFromNestedObject(() => this.searchOptions.courseDateSetStartDateFrom, undefined);
        if (input !== undefined) return input;
        else return this.formatDateTime(new Date(Date.now()), 'yyyy-MM-dd', 1);
      },
      buyingAvailability() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom && item.node.buyingAvailability !== 'passed')[0].node.buyingAvailability;
      },
      availablePlaces() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom && item.node.buyingAvailability !== 'passed')[0].node.availablePlaces;
      },
      buyableRow() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom)[0].node.courseDateSetDatePriceListRow.totalCount > 0;
      },
      nextCourseDateSetDate() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom)[0].node;
      },
      priceRowCheepest() {
        if (this.buyableRow)
          return this.courseDateSet.courseDateSetDateList.edges
            .filter((item) => item.node.startDate >= this.startDateFrom)[0]
            .node.courseDateSetDatePriceListRow.edges.sort((a, b) => {
              a.node.amount > b.node.amount;
            })[0].node.amount;
        else return undefined;
      },
      buyableSingle() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom)[0].node.courseDateSetDatePriceListSingle.totalCount > 0;
      },
      priceSingleCheepest() {
        if (this.buyableSingle)
          return this.courseDateSet.courseDateSetDateList.edges
            .filter((item) => item.node.startDate >= this.startDateFrom)[0]
            .node.courseDateSetDatePriceListSingle.edges.sort((a, b) => {
              a.node.amount > b.node.amount;
            })[0].node.amount;
        else return undefined;
      },
      countDates() {
        return this.courseDateSet.courseDateSetDateList.totalCount;
      },
      countFutureDates() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom).length;
      },
      firstDateDateTime() {
        return this.courseDateSet.courseDateSetDateList.edges[0].node.startDateTime;
      },
      firstDateFormattedDate() {
        return this.formatDateTime(new Date(this.firstDateDateTime), 'dd.MM.yyyy', 1);
      },
      nextDateDateTime() {
        return this.courseDateSet.courseDateSetDateList.edges.filter((item) => item.node.startDate >= this.startDateFrom)[0].node.startDateTime;
      },
      nextDateFormattedDate() {
        return this.formatDateTime(new Date(this.nextDateDateTime), 'dd.MM.yyyy', 1);
      },
      nextDateFormattedTime() {
        return this.formatDateTime(new Date(this.nextDateDateTime), 'HH:mm', 1);
      },
    },
    mounted() {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.elementVisible = true;
          observer.disconnect();
        }
      });

      observer.observe(this.$el);
    },
  };
</script>
