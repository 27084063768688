<template>
  <div>
    <label v-if="label" for="field" class="tw-mb-1 tw-block tw-text-sm tw-font-medium tw-text-gray-700">{{ label }}</label>
    <div class="tw-relative">
      <input
        type="file"
        name="field"
        id="field"
        ref="field"
        class="file:border-inherit file:bg-neutral-100 file:text-neutral-700 tw-block tw-w-full tw-rounded-md tw-border tw-pr-10 file:tw-overflow-hidden file:tw-rounded-none file:tw-border-0 file:tw-border-solid file:tw-px-3 file:tw-py-2 file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] focus:tw-outline-none sm:tw-text-sm"
        v-bind:class="{
          'tw-border-gray-300 focus:tw-border-indigo-500 focus:tw-ring-indigo-500': validationState === undefined,
          'tw-border-red-300 tw-text-red-900 tw-placeholder-red-300 focus:tw-border-red-500 focus:tw-ring-red-500': validationState === false,
          'tw-border-green-300 tw-text-green-900 tw-placeholder-green-300 focus:tw-border-green-500 focus:tw-ring-green-500': validationState === true,
        }"
        :placeholder="placeholder"
        aria-invalid="true"
        aria-describedby="email-error"
        @change="onFileChange"
      />

      <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3" v-if="validationState === false">
        <!-- Heroicon name: mini/exclamation-circle -->
        <svg class="tw-h-5 tw-w-5 tw-text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
        </svg>
      </div>

      <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3" v-if="validationState === true">
        <!-- Heroicon name: mini/check-circle -->
        <svg class="tw-h-5 tw-w-5 tw-text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <div class="tw-mt-1 tw-flex" v-if="!validationState || counterLabel">
      <div class="tw-grow tw-text-sm tw-text-red-600" id="field-error">{{ errors[0] }}</div>
      <div class="tw-justify-self-start tw-text-sm tw-font-medium tw-text-gray-700">{{ counterLabel }}</div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      label: {
        type: String,
        required: false,
      },
      placeholder: {
        type: String,
        required: false,
      },
      valid: {
        type: Boolean,
        required: false,
      },
      errors: {
        type: Array,
        required: false,
        default() {
          return [];
        },
      },
      counter: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        model: this.value,
      };
    },
    watch: {
      model: {
        deep: true,
        handler() {
          this.emit(this.model);
        },
      },
    },
    computed: {
      validationState() {
        if (this.errors.length > 0) {
          return false;
        } else if (this.valid === true) {
          return true;
        } else {
          return undefined;
        }
      },
      counterLabel() {
        if (this.counter && !this.model) {
          return `0/${this.counter}`;
        } else if (this.counter && this.model) {
          return `${this.model.length}/${this.counter}`;
        } else {
          return undefined;
        }
      },
    },
    methods: {
      onFileChange() {
        const files = this.$refs.field.files;
        this.model = files[0];
      },
      emit(val) {
        this.$emit('input', val);
        this.$emit('change');
      },
    },
  };
</script>
