import Vue from 'vue';

import HeliosAlert from '../components/Alert.vue';
Vue.component('helios-alert', HeliosAlert);

import HeliosInputCheckbox from '../components/InputCheckbox.vue';
Vue.component('helios-input-checkbox', HeliosInputCheckbox);

import HeliosBadge from '../components/Badge.vue';
Vue.component('helios-badge', HeliosBadge);

import HeliosButtom from '../components/Button.vue';
Vue.component('helios-button', HeliosButtom);

import HeliosSlideNavigation from '../components/SlideNavigation.vue';
Vue.component('helios-slide-navigation', HeliosSlideNavigation);

import HeliosCard from '../components/Card.vue';
Vue.component('helios-card', HeliosCard);

import HeliosDialog from '../components/Dialog.vue';
Vue.component('helios-dialog', HeliosDialog);

import HeliosDialog2 from '../components/Dialog2.vue';
Vue.component('helios-dialog-2', HeliosDialog2);

import HeliosInputText from '../components/InputText.vue';
Vue.component('helios-input-text', HeliosInputText);

import HeliosInputEmail from '../components/InputEmail.vue';
Vue.component('helios-input-email', HeliosInputEmail);

import HeliosInputDate from '../components/InputDate.vue';
Vue.component('helios-input-date', HeliosInputDate);

import HeliosInputDateTime from '../components/InputDateTime.vue';
Vue.component('helios-input-date-time', HeliosInputDateTime);

import HeliosSkeleton from '../components/Skeleton.vue';
Vue.component('helios-skeleton', HeliosSkeleton);

import HeliosLoading from '../components/Loading.vue';
Vue.component('helios-loading', HeliosLoading);

import HeliosInputFile from '../components/InputFile.vue';
Vue.component('helios-input-file', HeliosInputFile);

import HeliosBackButton from '../components/BackButton.vue';
Vue.component('helios-back-button', HeliosBackButton);