<template>
  <div v-if="type === 'router'">
    <router-link :to="to" class="tw-no-underline">
      <button type="button" :disabled="disabled" @click="$emit('click')" :class="buttonClass">
        <slot></slot>
        <svg :class="spinnerClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loading">
          <circle class="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </button>
    </router-link>
  </div>
  <div v-else-if="type === 'link'">
    <a :href="href" :target="target" class="tw-no-underline">
      <button type="button" :disabled="disabled" @click="$emit('click')" :class="buttonClass">
        <slot></slot>
        <svg :class="spinnerClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loading">
          <circle class="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </button>
    </a>
  </div>
  <div v-else-if="type === 'emit'">
    <button type="button" :disabled="disabled" @click="$emit('click')" :class="buttonClass">
      <slot></slot>
      <svg :class="spinnerClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loading">
        <circle class="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </button>
  </div>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        required: false,
        default: 'secondary',
      },
      size: {
        type: String,
        required: false,
        default: 'md',
      },
      to: {
        type: Object,
        required: false,
      },
      href: {
        type: String,
        required: false,
      },
      target: {
        type: String,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      full: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      type() {
        if (this.to) {
          return 'router';
        } else if (this.href) {
          return 'link';
        } else {
          return 'emit';
        }
      },
      buttonClass() {
        return [
          'tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-shadow-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 disabled:tw-cursor-not-allowed tw-cursor-pointer tw-no-underline',
          {
            'tw-text-white tw-bg-primary-600 hover:tw-bg-primary-700 tw-border-transparent focus:tw-ring-primary-500 disabled:tw-bg-primary-100': this.color === 'primary',
            'tw-text-gray-900 tw-bg-gray-200 hover:tw-bg-gray-300 tw-border-transparent focus:tw-ring-gray-300 disabled:tw-bg-gray-100': this.color === 'gray',
            'tw-ring-gray-300 tw-bg-white tw-text-gray-700 hover:tw-text-gray-500 focus:tw-ring-gray-200 tw-ring-1 tw-border-0': this.color === 'secondary',
            'tw-text-white tw-bg-red-600 hover:tw-bg-red-700 tw-border-transparent focus:tw-ring-red-500 disabled:tw-bg-red-300': this.color === 'attention',

            'tw-px-6 tw-py-1.5 tw-text-sm tw-font-medium': this.size === 'sm',
            'tw-px-6 tw-py-2 tw-text-base tw-font-medium': this.size === 'md',
            'tw-px-8 tw-py-2 tw-text-lg tw-font-medium': this.size === 'lg',

            'tw-w-full': this.full === true,
            'tw-w-full sm:tw-w-auto': this.full === false,
          },
        ];
      },
      spinnerClass() {
        return [
          'tw-ml-3 tw-h-5 tw-w-5 tw-animate-spin',
          {
            'tw-text-white': this.color === 'primary' || this.color === 'attention',
            'tw-text-gray-500': this.color === 'secondary',
          },
        ];
      },
    },
  };
</script>
