<template>
  <div class="">
    <nav class="sm:pl-0 pl-4 tw-flex tw-gap-4 tw-overflow-x-auto tw-py-4">
      <div class="tw-block sm:tw-hidden"></div>
      <router-link v-for="(item, index) in items" :key="item.name" v-slot="{ href, navigate, isActive, isExactActive }" :to="{ name: item.name }">
        <a
          :href="href"
          @click="navigate"
          :id="'navigation-link-' + item.name"
          class="tw-relative tw-inline-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-3xl tw-border tw-border-solid tw-border-gray-900 tw-px-6 tw-py-2 tw-text-sm tw-font-semibold tw-no-underline hover:tw-border-gray-800 hover:tw-bg-gray-800 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-800 focus:tw-ring-offset-2 md:tw-px-8 md:tw-py-3 md:tw-font-bold"
          v-bind:class="{
            'tw-bg-gray-900 tw-text-white': calculateIsActive(item, isActive, isExactActive) === true,
            'tw-bg-white tw-text-gray-900': calculateIsActive(item, isActive, isExactActive) !== true,
          }"
          >{{ item.title }}</a
        >
      </router-link>
    </nav>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      items: {
        type: Array,
      },
    },
    data() {
      return {
        model: undefined,
      };
    },
    watch: {
      $route() {
        this.scrollToActiveElement();
      },
    },
    methods: {
      scrollToActiveElement() {
        this.items.forEach((navigationItem) => {
          if (this.$router.currentRoute.matched.find((routerItem) => routerItem.name === navigationItem.name)) {
            const element = document.getElementById('navigation-link-' + navigationItem.name);
            element.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'end' });
            console.log('---- scrollToActiveElement()', 'navigation-link-' + navigationItem.name);
          }
        });
      },
      calculateIsActive(item, isActive, isExactActive) {
        if (item.exact === true) {
          if (isExactActive === true) {
            return true;
          }
        } else if (item.exact === false) {
          if (isActive === true) {
            return true;
          }
        } else {
          return false;
        }
      },
    },
    mounted() {
      this.scrollToActiveElement();
    },
  };
</script>
