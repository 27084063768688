const availabilityConfig = {
  passed: {
    colorHex: '#737373',
    backgroundColorClass: 'tw-bg-gray-600',
    textColorClass: 'tw-text-gray-600',
    text: 'Bereits vergangen',
  },
  passed_single: {
    colorHex: '#737373',
    backgroundColorClass: 'tw-bg-gray-600',
    textColorClass: 'tw-text-gray-600',
    text: 'Bereits vergangen',
  },
  reservation: {
    colorHex: '#219653',
    backgroundColorClass: 'tw-bg-green-600',
    textColorClass: 'tw-text-green-600',
    text: 'Durch Reservierung buchbar',
  },
  reservation_single: {
    colorHex: '#219653',
    backgroundColorClass: 'tw-bg-green-600',
    textColorClass: 'tw-text-green-600',
    text: 'Durch Reservierung buchbar',
  },
  low: {
    colorHex: '#d71010',
    backgroundColorClass: 'tw-bg-red-600',
    textColorClass: 'tw-text-red-600',
    text: 'Nicht mehr viele Plätze verfügbar',
  },
  low_single: {
    colorHex: '#d71010',
    backgroundColorClass: 'tw-bg-red-600',
    textColorClass: 'tw-text-red-600',
    text: 'Nicht mehr viele Plätze als Einzeltermin verfügbar',
  },
  medium: {
    colorHex: '#e08803',
    backgroundColorClass: 'tw-bg-yellow-500',
    textColorClass: 'tw-text-yellow-500',
    text: 'Nur noch wenige Plätze verfügbar',
  },
  medium_single: {
    colorHex: '#e08803',
    backgroundColorClass: 'tw-bg-yellow-500',
    textColorClass: 'tw-text-yellow-500',
    text: 'Nur noch wenige Plätze als Einzeltermin verfügbar',
  },
  good: {
    colorHex: '#219653',
    backgroundColorClass: 'tw-bg-green-600',
    textColorClass: 'tw-text-green-600',
    text: 'Ausreichend Plätze verfügbar',
  },
  good_single: {
    colorHex: '#219653',
    backgroundColorClass: 'tw-bg-green-600',
    textColorClass: 'tw-text-green-600',
    text: 'Ausreichend Plätze als Einzeltermin verfügbar',
  },
  not: {
    colorHex: '#d71010',
    backgroundColorClass: 'tw-bg-red-600',
    textColorClass: 'tw-text-red-600',
    text: 'Bereits ausgebucht',
  },
  not_single: {
    colorHex: '#d71010',
    backgroundColorClass: 'tw-bg-red-600',
    textColorClass: 'tw-text-red-600',
    text: 'Bereits ausgebucht',
  },

  default: {
    colorHex: '#737373',
    backgroundColorClass: 'tw-bg-gray-600',
    textColorClass: 'tw-text-gray-600',
    text: 'Default',
  },
};

function formatPlacesText(places) {
  if (places > 1) {
    return `Nur noch ${places} Plätze verfügbar`;
  }
  if (places === 1) {
    return `Nur noch 1 Platz verfügbar`;
  }
  return null;
}

export const CourseDateSetMixin = {
  methods: {
    mxn_courseDateSet_getCourseDateSetDateAvailable(courseDateSetDate) {
      if (this.courseDateSet.userReservationList.totalCount > 0) return true;
      else return courseDateSetDate.available;
    },

    mxn_courseDateSet_getCourseDateSetDateAvailabilityObject(courseDateSetDate) {
      const buyingAvailability = courseDateSetDate.buyingAvailability;
      const availablePlaces = courseDateSetDate.availablePlaces;
      const isRowNotBuyable = courseDateSetDate.buyingAvailabilityRow === 'not';
      const isUserHasReservation = this.courseDateSet.userReservationList?.totalCount > 0;

      if (buyingAvailability === 'passed') {
        return availabilityConfig.passed;
      }

      if (isUserHasReservation && buyingAvailability === 'reservation') {
        return availabilityConfig.reservation;
      }

      if (isRowNotBuyable) {
        if (availablePlaces) {
          if (buyingAvailability === 'low') {
            return {
              ...availabilityConfig.low_single,
              text: formatPlacesText(availablePlaces),
            };
          } else if (buyingAvailability === 'medium') {
            return {
              ...availabilityConfig.medium_single,
              text: formatPlacesText(availablePlaces),
            };
          } else if (buyingAvailability === 'good') {
            return {
              ...availabilityConfig.good_single,
              text: formatPlacesText(availablePlaces),
            };
          } else if (buyingAvailability === 'not') {
            return availabilityConfig.not_single;
          }
        } else {
          if (buyingAvailability === 'low') {
            return availabilityConfig.low;
          } else if (buyingAvailability === 'medium') {
            return availabilityConfig.medium;
          } else if (buyingAvailability === 'good') {
            return availabilityConfig.good;
          } else if (buyingAvailability === 'not') {
            return availabilityConfig.not;
          }
        }
      }

      if (availablePlaces) {
        if (buyingAvailability === 'low') {
          return {
            ...availabilityConfig.low,
            text: formatPlacesText(availablePlaces),
          };
        } else if (buyingAvailability === 'medium') {
          return {
            ...availabilityConfig.medium,
            text: formatPlacesText(availablePlaces),
          };
        } else if (buyingAvailability === 'good') {
          return {
            ...availabilityConfig.good,
            text: formatPlacesText(availablePlaces),
          };
        }
      } else {
        if (buyingAvailability === 'low') {
          return availabilityConfig.low;
        } else if (buyingAvailability === 'medium') {
          return availabilityConfig.medium;
        } else if (buyingAvailability === 'good') {
          return availabilityConfig.good;
        }
      }

      return availabilityConfig.default;
    },
  },
};
