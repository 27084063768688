<template>
  <div class="tw-flex" v-if="shouldShowBackButton">
    <div
      @click="goBack"
      class="tw-cursor-pointer tw-text-left"
      v-bind:class="{
        'tw-text-gray-900 hover:tw-text-gray-700': color === 'gray',
        'tw-text-white': color === 'white',
      }"
    >
      <div class="tw-flex tw-items-center">
        <div class="tw-block tw-h-8 tw-w-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-block tw-h-8 tw-w-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>

        <div class="tw-ml-2">
          <span class="tw-line-clamp-1 tw-text-sm tw-font-semibold"> {{ backButtonTitle }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    props: {
      defaultBackButton: {
        type: Object,
        default: null,
      },
      color: {
        type: String,
        default: 'gray',
      },
    },
    computed: {
      ...mapState(['navigationHistory']),
      backButtonTitle() {
        if (this.navigationHistory.length > 1) {
          // Get the second to last route in the history
          return this.navigationHistory[this.navigationHistory.length - 2].backButtonTitle;
        } else if (this.defaultBackButton) {
          return this.defaultBackButton.backButtonTitle;
        } else {
          return 'zurück';
        }
      },
      shouldShowBackButton() {
        return this.navigationHistory.length > 1 || this.defaultBackButton;
      },
    },
    methods: {
      ...mapMutations(['removeLastRouteFromHistory']),
      goBack() {
        let targetRoute;
        if (this.navigationHistory.length > 1) {
          targetRoute = this.navigationHistory[this.navigationHistory.length - 2];
        } else if (this.defaultBackButton) {
          targetRoute = this.defaultBackButton;
        }

        if (targetRoute && this.$route.fullPath !== targetRoute.currentRouteLink) {
          this.$router.push(targetRoute.currentRouteLink).then(() => {
            // Only remove the last route from history after successful navigation
            this.removeLastRouteFromHistory(); // Remove the site you're navigating to
            this.removeLastRouteFromHistory(); // Remove the current site
          });
        }
      },
    },
  };
</script>
