import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    sessionToken: null,
    page: undefined,
    isNative: false,
    nativeSafeAreaInsets: { top: 0, right: 0, bottom: 0, left: 0 },
    site: {
      website: {
        card: {
          defaultHeadImage: "https://altair-static.joinsports.de/joinsports_de/card-header-default.svg"
        },
        stage: {
          defaultBackgroundMid: "https://altair-static.joinsports.de/joinsports_de/stage-background-default-mid.svg",
        },
      },
      searchTypesSorting: ["courseDateSets", "pages"],
      searchReduceCategories: false,
      backend: {
        token: undefined
      }
    },
    application: {
      search: {
        activeType: undefined
      }
    }
  }),
  getters: {
    sessionToken(state) {
      return state.sessionToken
    }
  },
  mutations: {
    setSessionToken(state, input) {
      state.sessionToken = input
    },
    clearSessionToken(state) {
      state.sessionToken = undefined
    },
    setBackendToken(state, input) {
      state.site.backend.token = input
    }
  }
})
