import Vue from 'vue';
import App from './app.vue';
import router from 'plugins/router.js';
import store from 'src/store';
import apolloProvider from 'plugins/apolloProvider.js';
import getFromNestedObject from 'plugins/getFromNestedObject.js';
import formatDateTime from 'plugins/formatDateTime.js';
import vueCustomElement from 'vue-custom-element';
import styleLoader from 'plugins/styleLoader.js';
import includeComponents from 'helios/plugins/includeComponents.js'

Vue.config.productionTip = false;

App.store = store;
App.router = router;
App.styleLoader = styleLoader;
App.apolloProvider = apolloProvider;
App.getFromNestedObject = getFromNestedObject;
App.formatDateTime = formatDateTime;
App.includeComponents = includeComponents
Vue.use(vueCustomElement);
Vue.customElement('joinsports-widget-eris', App);