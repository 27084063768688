<template>
  <div class="tw-flex tw-items-center tw-justify-items-start">
    <span
      class="tw-inline-flex tw-items-center tw-truncate tw-rounded tw-font-light"
      v-bind:class="{
        'tw-bg-blue-100 tw-text-blue-700': color === 'primary',
        'tw-bg-gray-200 tw-text-grey-700': color === 'secondary',
        'tw-bg-red-100 tw-text-red-800': color === 'red',
        'tw-bg-yellow-100 tw-text-yellow-800': color === 'yellow',
        'tw-bg-green-100 tw-text-green-800': color === 'green',
        'tw-bg-white tw-opacity-90 tw-text-black': color === 'transparent',

        'tw-bg-teal-100 tw-text-teal-800': color === 'teal',
        'tw-bg-sky-100 tw-text-sky-800': color === 'sky',
        'tw-bg-blue-100 tw-text-blue-800': color === 'blue',

        'tw-text-[11px] tw-px-1 tw-py-0.5': size === 'xsmall',
        'tw-text-xs tw-px-2 tw-py-1': size === 'small',
        'tw-text-base tw-px-2 tw-py-1': size === 'medium',
        'tw-text-lg tw-px-2 tw-py-1': size === 'large',
      }"
      :title="$slots.default[0].text"
    >
      <svg
        v-if="dot"
        class="tw--ml-0.5 tw-mr-1 tw-h-2 tw-w-2"
        fill="currentColor"
        viewBox="0 0 8 8"
        v-bind:class="{
          'tw-text-gray-500': color === 'default',
          'tw-text-red-500': color === 'red',
          'tw-text-yellow-500': color === 'yellow',
          'tw-text-green-500': color === 'green',
          'tw-text-blue-500': color === 'primary',
          'tw-text-white': color === 'transparent',

          'tw-mb-[1px]': size === 'medium',
          'tw-mb-[2px]': size === 'large',
        }"
      >
        <circle cx="4" cy="4" r="3" />
      </svg>
      <slot></slot>
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        required: false,
        default: 'secondary',
      },
      dot: {
        type: Boolean,
        required: false,
        default: false,
      },
      size: {
        type: String,
        required: false,
        default: 'small',
      },
    },
  };
</script>
