export const GetFromNestedObjectMixin = {
  methods: {
    mxn_getFromNestedObject(nestedValFn, defaultVal) {
      try {
        if (nestedValFn() === undefined) {
          return defaultVal;
        } else {
          return nestedValFn();
        }
      } catch (e) {
        return defaultVal;
      }
    },
  },
};
