<template>
  <div v-if="show">
    <div class="tw-relative tw-z-50" role="dialog">
      <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"></div>
      <div
        class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto md:tw-mb-0"
        :style="{
          marginBottom: `${$store.state.nativeSafeAreaInsets.bottom > 0 ? $store.state.nativeSafeAreaInsets.bottom + 64 + 12 : $store.state.nativeSafeAreaInsets.bottom + 64}px`,
        }"
      >
        <div class="tw-flex tw-min-h-full tw-items-end tw-justify-center sm:tw-items-center sm:tw-p-0">
          <div
            class="tw-relative tw-mt-16 tw-w-full tw-transform tw-overflow-hidden tw-rounded-t-2xl tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-max-w-2xl sm:tw-rounded-lg sm:tw-p-6"
          >
            <div class="tw-absolute tw-right-0 tw-top-0 tw-pr-5 tw-pt-5">
              <button type="button" class="tw-cursor-pointer tw-rounded-md tw-border-0 tw-bg-white tw-text-gray-400 tw-ring-0 hover:tw-text-gray-500" @click="leave">
                <span class="tw-sr-only">Close</span>
                <!-- Heroicon name: outline/x -->
                <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="tw-mb-4">
              <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"><slot name="headline"></slot></h3>
            </div>
            <div>
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { EscapeHandler } from 'helios/mixins/escapeHandler';

  export default {
    mixins: [EscapeHandler],
    props: {},
    components: {},
    data() {
      return {
        show: false,
      };
    },
    computed: {},
    watch: {
      $route() {
        this.leave();
      },
    },
    methods: {
      enter() {
        return new Promise((resolve) => {
          this.show = true;
          resolve();
        });
      },
      leave() {
        this.show = false;
      },
      escapeHandler() {
        this.leave();
      },
    },
    mounted() {},
  };
</script>
