import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'abstract',
    routes: [
        {
            name: "App",
            path: "/",
            component: () => import("app/App.vue"),
            redirect: {name: 'AppSearch'},
            children: [
                {
                    name: "AppSearch",
                    path: "search",
                    component: () => import("app/search/App.vue"),
                }
            ]
        }
    ]
})