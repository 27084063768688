var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-items-center tw-justify-items-start"},[_c('span',{staticClass:"tw-inline-flex tw-items-center tw-truncate tw-rounded tw-font-light",class:{
      'tw-bg-blue-100 tw-text-blue-700': _vm.color === 'primary',
      'tw-bg-gray-200 tw-text-grey-700': _vm.color === 'secondary',
      'tw-bg-red-100 tw-text-red-800': _vm.color === 'red',
      'tw-bg-yellow-100 tw-text-yellow-800': _vm.color === 'yellow',
      'tw-bg-green-100 tw-text-green-800': _vm.color === 'green',
      'tw-bg-white tw-opacity-90 tw-text-black': _vm.color === 'transparent',

      'tw-bg-teal-100 tw-text-teal-800': _vm.color === 'teal',
      'tw-bg-sky-100 tw-text-sky-800': _vm.color === 'sky',
      'tw-bg-blue-100 tw-text-blue-800': _vm.color === 'blue',

      'tw-text-[11px] tw-px-1 tw-py-0.5': _vm.size === 'xsmall',
      'tw-text-xs tw-px-2 tw-py-1': _vm.size === 'small',
      'tw-text-base tw-px-2 tw-py-1': _vm.size === 'medium',
      'tw-text-lg tw-px-2 tw-py-1': _vm.size === 'large',
    },attrs:{"title":_vm.$slots.default[0].text}},[(_vm.dot)?_c('svg',{staticClass:"tw--ml-0.5 tw-mr-1 tw-h-2 tw-w-2",class:{
        'tw-text-gray-500': _vm.color === 'default',
        'tw-text-red-500': _vm.color === 'red',
        'tw-text-yellow-500': _vm.color === 'yellow',
        'tw-text-green-500': _vm.color === 'green',
        'tw-text-blue-500': _vm.color === 'primary',
        'tw-text-white': _vm.color === 'transparent',

        'tw-mb-[1px]': _vm.size === 'medium',
        'tw-mb-[2px]': _vm.size === 'large',
      },attrs:{"fill":"currentColor","viewBox":"0 0 8 8"}},[_c('circle',{attrs:{"cx":"4","cy":"4","r":"3"}})]):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }