<template>
  <div class="tw-flex tw-flex-col tw-rounded-xl tw-bg-gray-100 tw-drop-shadow-sm">
    <div class="tw-mb-3 tw-flex tw-px-6 tw-pt-4 tw-text-lg tw-font-semibold tw-text-gray-900" v-if="$slots.headline">
      <slot name="headline"></slot>
    </div>
    <div class="tw-h-full tw-px-6 tw-pb-6 tw-text-sm">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    props: {},
  };
</script>
