<template>
  <div>
      <Search :searchOptions="searchOptions" />
  </div>
</template>
<script>
  import Search from 'helios/modules/unifiedSearch'
  export default {
    props: {
      searchOptions: {
        type: Object,
        default() {
          return {
            criteria: {
              //typeNames: ["Kursangebot"],
              //pageSids: [this.$store.state.page.sid]
            },
            display: {
              categoryTitles: true
            },
            linkExternal: true
          };
        },
      },
    },
    components: {
      Search,
    },
    data() {
      return {};
    },
    mounted() {},
  };
</script>
