<template>
  <div
    class="tw-grid tw-h-full tw-w-full tw-content-between tw-overflow-hidden tw-rounded-xl tw-bg-cover"
    :style="'background-image:' + titleImage.image + ';' + 'background-size: cover;' + 'background-position: ' + titleImage.position + ';'"
  >
    <!-- Top -->
    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-rounded-xl tw-px-6 tw-pb-8 tw-pt-8" style="background: linear-gradient(180deg, #2f2f2f -60%, rgba(0, 0, 0, 0) 100%)">
      <helios-badge color="transparent"
        ><div class="tw-mr-1.5 tw-h-2 tw-w-2 tw-rounded-full" v-bind:class="availabilityText.backgroundColor"></div>
        {{ availabilityText.text }}</helios-badge
      >

      <helios-badge color="transparent">Aboangebot</helios-badge>
    </div>

    <!-- Bottom -->
    <div class="tw-rounded-xl tw-px-6 tw-pb-6 tw-pt-12" style="background: linear-gradient(360deg, #1f1f1f 20%, rgba(0, 0, 0, 0) 100%)">
      <helios-badge color="transparent" class="tw-mb-2.5">{{ subscription.page.title }}</helios-badge>

      <!-- Title -->
      <div class="tw-mb-2 tw-text-lg tw-font-bold tw-leading-tight tw-text-white">{{ subscription.title }}</div>

      <!-- About -->
      <!--<div class="tw-text-base tw-font-semibold tw-text-white tw-line-clamp-6" v-if="page.aboutShort">{{page.aboutShort}}</div>-->

      <!-- Price -->
      <div class="tw-mb-2 tw-flex tw-place-items-end tw-text-white">
        <div class="tw-mr-2 tw-text-lg tw-font-semibold">ab {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(priceCheapest / 100) }}</div>
        <div class="tw-pb-[2px] tw-text-sm">pro Monat</div>
      </div>

      <div class="tw-mt-4 tw-flex tw-justify-end">
        <router-link
          class="tw-text-left tw-text-white tw-no-underline"
          :to="{ name: 'AppPageSubscriptionsSubscription', params: { pageAlias: subscription.page.alias, subscriptionSid: subscription.sid } }"
          v-if="this.searchOptions.linkExternal === false"
        >
          <div class="tw-flex tw-items-center">
            <div class="tw-mr-2">
              <span class="tw-line-clamp-1 tw-text-sm tw-font-semibold"> Aboangebot öffnen </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-block tw-h-8 tw-w-8 tw-rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </router-link>
        <a
          :href="'https://joinsports.de/' + subscription.page.alias + '/subscriptions/' + subscription.sid"
          :title="subscription.title + ' bei joinsports.de'"
          v-if="this.searchOptions.linkExternal === true"
          class="tw-cursor-pointer tw-text-white tw-no-underline"
          target="_blank"
        >
          <div class="tw-flex tw-items-center">
            <div class="tw-mr-2">
              <span class="tw-line-clamp-1 tw-text-sm tw-font-semibold"> Aboangebot auf joinsports.de öffnen </span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-block tw-h-8 tw-w-8 tw-rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      subscription: {
        required: true,
        type: Object,
      },
      searchOptions: {
        required: true,
        type: Object,
      },
    },
    computed: {
      titleImage() {
        return {
          image: 'url("' + this.$store.state.site.website.stage.defaultBackgroundMid + '")',
          position: 'right bottom',
        };
      },
      availablePlaces() {
        return this.subscription.availabilityPerDate[0].availableSlots;
      },
      availabilityText() {
        if (this.availablePlaces > 5)
          return {
            textColor: 'tw-text-green-600',
            backgroundColor: 'tw-bg-green-600',
            text: 'Ausreichend Plätze verfügbar',
          };
        else if (this.availablePlaces > 3)
          return {
            textColor: 'tw-text-yellow-500',
            backgroundColor: 'tw-bg-yellow-500',
            text: 'Noch wenige Plätze verfügbar',
          };
        else if (this.availablePlaces > 1)
          return {
            textColor: 'tw-text-red-600',
            backgroundColor: 'tw-bg-red-600',
            text: 'Noch ' + this.availablePlaces + ' Plätze verfügbar',
          };
        else if (this.availablePlaces === 1)
          return {
            textColor: 'tw-text-red-600',
            backgroundColor: 'tw-bg-red-600',
            text: 'Noch ' + this.availablePlaces + ' Platz verfügbar',
          };
        if (this.availablePlaces === 0)
          return {
            textColor: 'tw-text-red-600',
            backgroundColor: 'tw-bg-red-600',
            text: 'Bereits ausgebucht',
          };
        else
          return {
            textColor: 'tw-text-gray-600',
            backgroundColor: 'tw-bg-gray-600',
            text: 'Default',
          };
      },
      priceCheapest() {
        return this.subscription.subscriptionConditionList.edges[0].node.price;
      },
    },
  };
</script>
