<template>
  <div
    v-if="searchOptionsProcessed.searchPhrase.length > 0"
    class="tw-mr-4 tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-3xl tw-border-2 tw-border-solid tw-border-slate-900 tw-bg-white tw-px-4 tw-py-1"
  >
    <div class="tw-mr-2 tw-grid tw-grid-cols-1">
      <div class="tw-mr-2 tw-text-xs">Suchanfrage</div>
      <div class="tw-mr-2 tw-flex tw-flex-nowrap tw-truncate tw-text-ellipsis tw-text-xs tw-font-semibold">
        {{ searchOptionsProcessed.searchPhrase }}
      </div>
    </div>

    <button type="button" class="tw-rounded-md tw-border-none tw-bg-white tw-text-slate-900 hover:tw-text-slate-700" @click="clearFilter()">
      <!-- Heroicon name: outline/x -->
      <svg class="tw-h-7 tw-w-7 tw-pt-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
</template>
<script>
  export default {
    props: {
      searchOptionsProcessed: {
        required: true,
        type: Object,
      },
    },
    methods: {
      clearFilter() {
        this.searchOptionsProcessed.searchPhrase = '';
        this.dispatchFilter();
      },
      dispatchFilter() {
        this.$emit('search-phrase-changed', this.searchOptionsProcessed.searchPhrase);
      },
    },
  };
</script>
