<template>
  <div v-if="ready === true">
    <div>
      <router-view></router-view>
    </div>
    <div class="tw-border-t tw-border-solid tw-border-slate-200 tw-mt-4">

    </div>
    <div class="tw-mt-4 tw-grid tw-grid-cols-2 tw-mb-4">
      <div>
        <a :href="'https://joinsports.de/' + $store.state.page.alias" target="_blank" class="tw-text-slate-700 tw-text-sm">{{ $store.state.page.title }} bei joinsports.de</a>
      </div>
      <div class="tw-justify-self-end">
        <a :href="'https://joinsports.de/' + $store.state.page.alias" style="display: block; margin-top: 4px" target="_blank">
          <img src="https://altair-static.joinsports.de/joinsports_de/header-logo-wide.svg" alt="JoinSports" style="height: 30px" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag';

  export default {
    data() {
      return {
        ready: false,
      };
    },
    apollo: {
      loadApplication: {
        query: gql`
          query {
            session {
              token
              channel {
                id
                page {
                  sid
                  title
                  alias
                }
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
        prefetch: false,
        update(result) {
          this.$store.state.page = result.session.channel.page;
          this.ready = true;
        },
      },
    },
  };
</script>
<style lang="stylus">

</style>
