<template>
  <div>
    <div v-if="type === '2chars'" class="tw-mt-1 tw-animate-pulse tw-rounded-md tw-border-2">
      <div class="tw-flex tw-gap-1">
        <div class="tw-h-4 tw-w-2 tw-rounded-md tw-bg-gray-200"></div>
        <div class="tw-h-4 tw-w-2 tw-rounded-md tw-bg-gray-200"></div>
      </div>
    </div>
    <div v-if="type === '2lines'" class="tw-mt-1 tw-animate-pulse tw-rounded-md tw-border-2">
      <div class="tw-grid tw-gap-1">
        <div class="tw-h-3 tw-w-48 tw-rounded-md tw-bg-gray-200"></div>
        <div class="tw-h-3 tw-w-36 tw-rounded-md tw-bg-gray-200"></div>
      </div>
    </div>
    <div v-else-if="type === '4lines'" class="tw-mt-1 tw-animate-pulse tw-rounded-md tw-border-2">
      <div class="tw-grid tw-gap-1">
        <div class="tw-h-3 tw-w-72 tw-rounded-md tw-bg-gray-200 sm:tw-w-96"></div>
        <div class="tw-h-3 tw-w-72 tw-rounded-md tw-bg-gray-200 sm:tw-w-96"></div>
        <div class="tw-h-3 tw-w-72 tw-rounded-md tw-bg-gray-200 sm:tw-w-96"></div>
        <div class="tw-h-3 tw-w-52 tw-rounded-md tw-bg-gray-200 sm:tw-w-64"></div>
      </div>
    </div>
    <div v-if="type === 'headline'" class="tw-mt-1 tw-animate-pulse tw-rounded-md tw-border-2">
      <div class="tw-grid tw-gap-1">
        <div class="tw-h-9 tw-w-96 tw-rounded-md tw-bg-gray-200"></div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      type: {
        type: String,
        default: '2lines',
      },
    },
  };
</script>
